<template>
  <div class="card card-custom card-stretch gutter-b bg-dark">
    <!--begin::Body-->
    <div v-if="thePlant" class="d-flex flex-column flex-grow-1">
      <div>
        <!--begin::title-->
        <div
          class="text-white mx-8 mt-6 font-weight-bolder font-size-h4 bg-dark"
        >
          {{ thePlant.NAME }}
        </div>
        <!--end::title-->
        <!--begin::image-->
        <div class="h-md-90">
          <img ref="siteImage" style="height: 200px; width: 100%" />
        </div>
        <!--end::image-->
      </div>
      <!--begin::desc-->
      <div class="card flex-grow-1">
        <div class="card-body d-flex flex-column">
          <!--begin::address-->
          <p class="text-primary">{{ thePlant.ADDRESS }}</p>

          <!--end::address-->
          <!--begin::prop-->
          <div
            :class="{
              healthy: systemHealth,
            }"
          >
            <div class="systemStatus">
              <h5 class="py-4">System Status</h5>
              <div class="systemHealth">
                <span>
                  {{ systemHealth ? "Running" : "Stopped" }}
                </span>
                <span class="runningIndicator"></span>
              </div>
            </div>
            <div v-if="healthReason" class="healthReason">
              {{ healthReason }}
            </div>

            <div class="soc">{{ Math.round(currentSoC * 1000) / 10 }}%</div>
            <div class="chargeBar">
              <span
                v-if="systemHealth"
                v-bind:style="{
                  'grid-column-end': `span ${Math.round(currentSoC * 100)}`,
                }"
              >
              </span>
              <span
                v-else
                class="stopped"
                style="grid-column-end: span 100"
              ></span>
            </div>
            <div class="d-flex flex-column mb-5">
              <div class="d-flex justify-content-between">
                <div class="text-dark-75 text-uppercase" cols="5">
                  Battery Capacity
                </div>
                <div class="text-dark-75" cols="4">
                  {{ Math.round(batteryAvailableWh / 100) / 10 }} kWh /
                  {{ Math.round(effectiveCapacityWh / 100) / 10 }} kWh
                </div>
              </div>
            </div>
          </div>
          <!--end::prop-->
          <!--begin::button-->
          <b-button variant="primary" @click="changeRoute"
            >Performance &amp; Benefits</b-button
          >
          <!--end::button-->
        </div>
      </div>
      <!--end::desc-->
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
/*
needs:
- reload on interval
- alternate data for installed equipment
- bar graph for battery charge: https://codepen.io/marionnewlevant/pen/wvLORXa
*/
import ApiService from "@/core/services/api.service";
import { checkPropertyUnit } from "@/helpers/Regex";
import { Auth } from "aws-amplify";

export default {
  name: "PlantNewCard",
  props: ["thePlant"],
  data() {
    return {
      user: {},
      session: {},
      // information from get_site_data:
      batteryAvailableWh: 0,
      effectiveCapacityWh: 0,
      systemHealth: true,
      healthReason: "",
      currentSoC: 0, // calculated from batteryAvailableWh/effectiveCapacityWh
    };
  },
  async mounted() {
    this.user = await Auth.currentAuthenticatedUser();
    this.session = await Auth.currentSession();
    this.getImage(this.thePlant.SITE_ID);
    this.getSiteData(this.thePlant.SITE_ID);
    setInterval(() => {
      this.getSiteData(this.thePlant.SITE_ID);
    }, 60000); // update every minute
  },
  methods: {
    getImage(siteId) {
      this.$refs.siteImage.src =
        "https://cgui-api.motivedg.com:5000/api/get_site_image?jwtToken=" +
        this.user.signInUserSession.idToken.jwtToken +
        "&siteId=" +
        siteId;
    },
    changeRoute() {
      this.$router.push(`/performance/${this.thePlant.SITE_ID}`);
    },
    getSiteData(siteId) {
      ApiService.query("/api/etl/get_site_data", {
        params: {
          jwtToken: this.user.signInUserSession.idToken.jwtToken,
          siteId: siteId,
        },
      })
        .then((res) => {
          this.batteryAvailableWh = res.data.batteryAvailableWh;
          this.effectiveCapacityWh = res.data.effectiveCapacityWh;
          this.systemHealth = res.data.systemHealth;
          this.healthReason = res.data.healthReason;
          if (
            this.batteryAvailableWh < 0 ||
            this.effectiveCapacityWh <= 0 
          ) {
            this.batteryAvailableWh = 0;
            this.effectiveCapacityWh = 0;
            this.systemHealth = false;
            this.currentSoC = 0;
            if (this.healthReason === "") {
              this.healthReason = "Battery data problem (CPD #9)";
            }
          } else {
            if (this.batteryAvailableWh > this.effectiveCapacityWh) {
              this.batteryAvailableWh = this.effectiveCapacityWh;
            }
            this.currentSoC =
              this.batteryAvailableWh / this.effectiveCapacityWh;
          }
        })
        .catch((error) => {
          this.systemHealth = false;
          this.healthReason = "Error retrieving data (CPD #1)";
          this.currentKWh = 0;
          this.totalKWh = 0;
          this.currentSoC = 0;
        });
    },
  },
};
</script>
<style scoped>
.chargeBar {
  display: grid;
  grid-template-columns: repeat(100, 1fr);
  border: 2px solid rgba(0, 0, 0, 0.87);
  margin-bottom: 1rem;
}
.chargeBar span {
  display: flex;
  grid-column-start: 1;
  background: linear-gradient(to bottom, #888, #666);
  padding-top: 2rem;
}
.chargeBar span.stopped {
  background: #f86464;
}

.systemStatus,
.systemHealth {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
}
.runningIndicator {
  display: block;
  border: 2px solid rgba(0, 0, 0, 0.87);
  width: 3rem;
  height: 3rem;
  border-radius: 3rem;
  background-color: #f86464;
}
.healthy .runningIndicator {
  background-color: #6ce252;
}
.soc {
  padding-top: 1rem;
}
</style>
