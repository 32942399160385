<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row">
      <!-- <div class="col-xxl-4">
        <MixedWidget1></MixedWidget1>
      </div> -->
      <!-- <div class="col-xxl-8">
        <ListWidget9></ListWidget9>
      </div>

      <div class="col-xxl-4">
        <ListWidget1></ListWidget1>
      </div>
      <div class="col-xxl-8">
        <AdvancedTableWidget2></AdvancedTableWidget2>
      </div> -->

      <!-- <div class="col-md-6 col-lg-4 col-xl-3">
        <ListWidget11></ListWidget11>
      </div>
      <div class="col-md-6 col-lg-4 col-xl-3">
        <ListWidget12></ListWidget12>
      </div>
      <div class="col-md-6 col-lg-4 col-xl-3">
        <ListWidget13></ListWidget13>
      </div> -->

      <!--begin::overview cards-->
      <div
        v-for="plant in getEmsList"
        :key="plant.SITE_ID"
        class="col-md-6 col-lg-4 col-xl-4"
      >
        <ThePlantOverviewCard
          v-if="!isLoading && !plant.NEW_DASHBOARD"
          :thePlant="plant"
        />
        <ThePlantNewCard
          v-if="!isLoading && plant.NEW_DASHBOARD"
          :thePlant="plant"
        />
      </div>

      <!--end::overview cards-->
    </div>

    <!-- <div class="row">
      <div class="col-xxl-6 order-1 order-xxl-2">
        <ListWidget14></ListWidget14>
      </div>
      <div class="col-xxl-6 order-1 order-xxl-2">
        <ListWidget15></ListWidget15>
      </div>
    </div> -->
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
// import AdvancedTableWidget2 from "@/view/content/widgets/advance-table/Widget2.vue";
// import MixedWidget1 from "@/view/content/widgets/mixed/Widget1.vue";
// import ListWidget1 from "@/view/content/widgets/list/Widget1.vue";
// import ListWidget9 from "@/view/content/widgets/list/Widget9.vue";
// import ListWidget11 from "@/views/content/widgets/list/Widget11.vue";
// import ListWidget12 from "@/views/content/widgets/list/Widget12.vue";
// import ListWidget13 from "@/views/content/widgets/list/Widget13.vue";
// import ListWidget14 from "@/view/content/widgets/list/Widget14.vue";
// import ListWidget15 from "@/view/content/widgets/list/Widget15.vue";
import ThePlantOverviewCard from "@/components/ThePlantOverviewCard.vue";
import ThePlantNewCard from "@/components/ThePlantNewCard.vue";
// import ApiService from "@/core/services/api.service";
import store from "@/core/services/store";
import { GET_EMS_LIST } from "@/store/ems.store";
import { mapGetters } from "vuex";
import { Auth } from "aws-amplify";

export default {
  name: "dashboard",
  components: {
    ThePlantOverviewCard,
    ThePlantNewCard,
    // AdvancedTableWidget2,
    // MixedWidget1,
    // ListWidget1,
    // ListWidget9,
    // ListWidget11,
    // ListWidget12,
    // ListWidget13
    //   ListWidget14,
    //   ListWidget15
  },
  data() {
    return {
      emsList: [],
      isLoading: false,
    };
  },
  async mounted() {
    console.log(window.location.origin, "SCOTT");
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    let user = null;
    let session = null;
    try {
      user = await Auth.currentAuthenticatedUser();
      session = await Auth.currentSession();
      user.refreshSession(session.refreshToken, (err) => {
        if (err != null) {
          this.$router.push({ name: "login" });
        }
      });
    } catch (error) {
      this.$router.push({ name: "login" });
      return;
    }

    store.dispatch(GET_EMS_LIST, user);
    this.emsList = this.getEmsList;
  },
  methods: {
    // setActiveTab1(event) {
    //   this.tabIndex = this.setActiveTab(event);
    // },
    // setActiveTab2(event) {
    //   this.tabIndex2 = this.setActiveTab(event);
    // },
    // /**
    //  * Set current active on click
    //  * @param event
    //  */
    // setActiveTab(event) {
    //   // get all tab links
    //   const tab = event.target.closest('[role="tablist"]');
    //   const links = tab.querySelectorAll(".nav-link");
    //   // remove active tab links
    //   for (let i = 0; i < links.length; i++) {
    //     links[i].classList.remove("active");
    //   }
    //   // set current active tab
    //   event.target.classList.add("active");
    //   // set clicked tab index to bootstrap tab
    //   return parseInt(event.target.getAttribute("data-tab"));
    // }
  },
  computed: {
    ...mapGetters(["getEmsList"]),
  },
};
</script>
